import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#292D32\" d=\"M17.74 22.75h-5.48c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.48c1.66 0 3.01-1.35 3.01-3.01v-.44H19.6c-1.51 0-2.79-1.12-2.91-2.56-.08-.83.22-1.64.82-2.23.51-.52 1.21-.81 1.96-.81h1.27v-.69c0-1.66-1.35-3.01-3.01-3.01H6.25c-1.66 0-3.01 1.35-3.01 3.01v1.73c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-1.73C1.74 9.02 3.76 7 6.25 7h11.48c2.49 0 4.51 2.02 4.51 4.51v1.44c0 .41-.34.75-.75.75h-2.02c-.35 0-.67.13-.9.37-.29.28-.43.66-.39 1.04.06.66.69 1.19 1.41 1.19h1.9c.41 0 .75.34.75.75v1.19a4.496 4.496 0 0 1-4.5 4.51\"></path><path fill=\"#292D32\" d=\"M2.5 13.16c-.41 0-.75-.34-.75-.75V7.84c0-1.49.94-2.84 2.33-3.37l7.94-3c.82-.31 1.73-.2 2.44.3.72.5 1.14 1.31 1.14 2.18v3.8c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-3.8c0-.38-.18-.73-.5-.95s-.7-.27-1.06-.13l-7.94 3c-.81.31-1.36 1.1-1.36 1.97v4.57c.01.42-.33.75-.74.75M19.6 17.8c-1.51 0-2.79-1.12-2.91-2.56-.08-.83.22-1.64.82-2.23.51-.52 1.21-.81 1.96-.81h2.08c.99.03 1.75.81 1.75 1.77v2.06c0 .96-.76 1.74-1.72 1.77zm1.93-4.1h-2.05c-.35 0-.67.13-.9.37-.29.28-.43.66-.39 1.04.06.66.69 1.19 1.41 1.19h1.96c.13 0 .25-.12.25-.27v-2.06c0-.15-.12-.26-.28-.27\"></path><path fill=\"#292D32\" d=\"M14 12.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7c.41 0 .75.34.75.75s-.34.75-.75.75M9.5 19.69c-.41 0-.75-.34-.75-.75v-1.28c0-.22-.18-.41-.41-.41H3c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.34c1.05 0 1.91.86 1.91 1.91v1.28c0 .41-.34.75-.75.75\"></path><path fill=\"#292D32\" d=\"M4.22 18.47c-.19 0-.38-.07-.53-.22l-1.22-1.22a.755.755 0 0 1 0-1.06l1.22-1.22c.29-.29.77-.29 1.06 0s.29.77 0 1.06l-.69.69.69.69c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22M9.5 22.53H4.16c-1.05 0-1.91-.86-1.91-1.91v-1.28c0-.41.34-.75.75-.75s.75.34.75.75v1.28c0 .22.18.41.41.41H9.5c.41 0 .75.34.75.75s-.34.75-.75.75\"></path><path fill=\"#292D32\" d=\"M8.28 23.75c-.19 0-.38-.07-.53-.22a.755.755 0 0 1 0-1.06l.69-.69-.69-.69a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l1.22 1.22c.29.29.29.77 0 1.06l-1.22 1.22c-.14.15-.34.22-.53.22\"></path>", 5)
  ])))
}
export default { render: render }